//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CollectionsMain',
  props: {
    section: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      collections: [],
    }
  },
  async mounted() {
    await this.$axios
      .$get('/ProductCategories/RetailCatalogs')
      .then((productCatalogs) => {
        this.collections = productCatalogs
      })
  },
}
